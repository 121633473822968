if($(window).width() < 1100) {
    $('.hide1100').remove();
} else {
    $('.show859 #voice-search-trigger').remove();
    $('.show859 #voice-search-target').remove();
}

$(document).ready(function (ev) {
    let trigger = $("#voice-search-trigger");
    let target = $("#voice-search-target");

    function initStartClick(){
        trigger.unbind().click(function () {
            trigger.off("click");
            if (window.hasOwnProperty('webkitSpeechRecognition')) {
                target.val("");

                let recognition = new webkitSpeechRecognition();

                recognition.continuous = false;
                recognition.interimResults = true;

                recognition.lang = "ru-RU";
                recognition.start();

                recognition.onresult = function(e) {
                    let value = e.results[0][0].transcript;

                    if(value){
                        target.val(value);
                    }
                };

                recognition.onend = function(){
                    target.trigger("input");
                    stopRecognition(recognition);
                };

                recognition.onerror = function(e) {
                    console.log("error");
                    recognition.stop();
                };
            }
        });
    }

    function stopRecognition(recognition){
        recognition.stop();

        initStartClick();
    }

    initStartClick();
});